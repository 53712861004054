import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">    

    <p className="lead">
      Demo authentication SPA client
    </p>
  </div>
);

export default Hero;
