import React from "react";
import { Router, Route, Switch } from "react-router-dom";


import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import { Highlight } from "highlight.js/lib/core";
import { Container, Row, Col } from "reactstrap";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  
  const { isLoading, error,user, getIdTokenClaims, isAuthenticated} = useAuth0();
// if(isAuthenticated)
// {
//   getIdTokenClaims.then(result => this.token=result).catch( err => console.log(err));  
// }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        {/* <Container>
         
            
         
            <Row className="align-items-center profile-header mb-5 text-center text-md-left">
              <Highlight>{JSON.stringify(this.token, null, 2)   }</Highlight>
            </Row>
         
     
        </Container> */}
        <Container className="flex-grow-1 mt-5">
          <Switch>
           <Route path="/signin-callback" exact component={Home} /> 
            <Route path="/getAToken" exact component={Home} />
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
